/* @import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap'); */

@font-face {
  font-family: manaspc;
  src: url(manaspc.ttf);
}

html {
  scroll-behavior: smooth;
  cursor: url("./components/assets/cursor.png"), auto;
  background-color: black !important;
}

* {
  box-sizing: border-box;
  /* font-family: "Rubik", sans-serif; */
  font-family: 'manaspc';
  /* color:#0a0a0a; */
  color: white;
  margin: 0;
  padding: 0;
  font-weight: bold;
  cursor: url("./components/assets/cursor.png"), auto;
}

body {
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  cursor: url("./components/assets/cursor.png"), auto;
}

/*main page scroll bar*/
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #ffa500;
  border-radius: 0px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background: black;
}
/*main page scroll bar*/

@keyframes animateDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes rocket {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes hover {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  } 
}

a {
  text-decoration: none !important;
}