.App {
background-color: black !important;
}

.glow:before,
.glow:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    border-radius: 4px;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    background: linear-gradient(-45deg, #FB0712, #124FEB);
    background-size: 400%;
    z-index: -1;
    animation: change 40s linear infinite
}

@keyframes change {
    0% {
        background-position: 0 0
    }

    50% {
        background-position: 400% 0
    }

    100% {
        background-position: 0 0
    }
}